.similar-product-img{
    width: 54.85px;
    height: 50px;
    border-radius: 15px;
    border: none;
    object-fit: cover;
    object-position: center;
}

.card.similar-product-card{
    background-color: #F3F3F3;
    border: none;
    height: 51px;
}

.similar-product-category {
    cursor: pointer;
    height: 63px;
    width: 60.85px;
}
