.gm-style-mtc {
	display: none !important;
}

.gm-svpc {
	display: none !important;
}

.pac-container,
.pac-item {
	z-index: 214748364700 !important;
}
​ .map-close-btn {
	border-bottom-left-radius: 3px !important;
	border-bottom-right-radius: 3px !important;
	border-top-left-radius: 0px !important;
	border-top-right-radius: 0px !important;
	font-weight: 700;
	color: #72953e !important;
	border-color: transparent !important;
}

#mapCloseBtn {
	font-weight: 500;
	color: #000 !important;
	border-color: transparent !important;
}

/* span.ant-radio + * {
    background-color: #FFF9D9;
    margin-left: 5px;
} */

.theme-radios .ant-radio-checked .ant-radio-inner {
	border-color: #006c67 !important ;
}

.theme-radios .ant-radio-checked .ant-radio-inner:after {
	background-color: #006c67;
}

.theme-radios .ant-radio:hover .ant-radio-inner {
	border-color: #006c67;
}
.theme-radios .ant-radio-checked .ant-radio-inner:focus {
	border-color: #006c67;
}

.modal-content {
	/* background-color: #F3F3F3; */
}

.accordion-button {
	padding: 5px 10px !important;
}

/* previous location */
.ant-collapse > .ant-collapse-item {
	border-bottom: 0px solid #d9d9d9 !important;
}
.ant-collapse-borderless > .ant-collapse-item {
	border-bottom: 0px solid #d9d9d9 !important;
}

.ant-collapse > .ant-collapse-item {
	border-bottom: 0px solid #d9d9d9 !important;
}

/* .or {
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 5px 0px;
} */
