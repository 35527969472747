.cate-wrapper {
  overflow-x: scroll;
  overflow-y: hidden;
}

.cate-wrapper::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.top-title {
  font-size: 0.97rem !important;
  font-weight: bold;
}
.sticky-top {
  position: sticky !important;
  top: 0;
  z-index: 2;
}

.categories{
  position: relative;
}

.left-arrow{
  position: absolute;
  left: -7px;
  transform: translateY(70%);
  cursor: pointer;
  z-index: 1;
}

.right-arrow{
  position: absolute;
  right: -1px;
  transform: translateY(70%);
  cursor: pointer;
  z-index: 1;
}
.categories-tabs .ant-tabs-tab{
  align-items: flex-start;
}

.categories-tabs.ant-tabs > .ant-tabs-nav .ant-tabs-nav-operations{
  display: none;
}
.categories-tabs.ant-tabs-top > .ant-tabs-nav::before{
  border-bottom: none;
}