.resizedThumb {
	height: 60px;
	width: 60px;
}

.categoryIcon {
	color: #006c67;
}

.cat-card {
	border-radius: 40px !important;
}

/* Category */
.card-size {
	width: 4rem;
	height: 4.5rem;
}

.card-img {
	width: 50px !important;
	height: 50px !important;
}

.card-bdr {
	border: 2px solid #006c67 !important;
}

.category-card {
	width: 64px;
	height: 64px;
	border-radius: 50%;
	border: none;
}
.category-img {
	width: 60px !important;
	height: 60px;
	border: none;
	border-radius: 100%;
}

.category-title {
	font-style: normal;
	font-weight: 500;
	font-size: 10px;
	line-height: 16px;
	width: 64.16px;
	display: -webkit-box;
	white-space: initial;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
.category {
	cursor: pointer;
}

.card.sub-category {
	border-radius: 20px;
	background-color: #ffffff;
	cursor: pointer;
	display: inline-block;
	padding: 8px;
	border: none;
	margin-right: 10px;
}

.text-sub-category {
	max-width: 100%;
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 20px;
	color: #333333;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.sub-category-selected {
	background-color: white !important;
	border: 1px solid #006c67 !important;
}

.sub-category-selected > p {
	color: #006c67 !important;
}
.category-selected-text{
	font-weight: 700;
	font-size: 11px;
}
.ant-tabs-ink-bar {
    background: transparent;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: rgba(0, 0, 0, 0.85);
	text-shadow: none;
}

.ant-tabs-tab {
	padding: 5px 0;
}