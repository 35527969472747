.text-middle {
    margin: 0;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }

  .btn-green{
    background-color: #006c67;
    border-color: #006c67;
    color:white;
  }
  
  .lead{
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.01em;
  }

  .lead span{
    font-weight: 600;
  }