.user-information {
	border-radius: 10px;
}

.img-dp {
	width: 60px !important;
	height: 60px !important;
}

.user-text {
	font-size: 0.7rem;
}

/* Item information  */
.text-grey {
	color: rgb(150, 150, 150) !important;
}

.text-small {
	font-size: 0.8rem;
}

.text-link {
	color: #00629b;
}

.map-text {
	text-decoration: none !important;
}

/* additional-note */
.additional-note {
	min-height: 80px !important;
	border-radius: 10px !important;
	min-height: auto;
}

.input-box {
	background-color: #e0e2ee !important;
	font-size: 0.8rem !important;
	border: 1px solid #e0e2ee00 !important;
}

/* Payment  */
.radio-button-label > input {
	display: none;
}

.radio-button-label > img {
	cursor: pointer;
	border: 3px solid #ddd;
	width: 150px;
}

.radio-button-label > input:checked + img {
	border: 3px solid #2885bb;
}

.payment-img {
	width: 40px !important;
	border-radius: 5px !important;
}

/* place-picker */
.place-picker {
	border-radius: 10px !important;
}

.select2-drop-active {
	margin-top: -25px;
}

.radio-button-label > input {
	display: none;
}
.radio-button-label > img {
	cursor: pointer;
	border: 3px solid #ddd;
	width: 150px;
}

.radio-button-label > input:checked + img {
	border: 3px solid #2885bb;
}

.select2-container .select2-selection--single .select2-selection__rendered {
	font-size: 0.8rem !important;
}

.select2-container--default .select2-selection--single {
	background-color: #e0e2ee !important;
	border: 1px solid #e0e2ee00 !important;
	border-radius: 10px !important;
	margin: 5px !important;
}
.select2-container--default .select2-selection--single .select2-selection__arrow {
	height: 26px;
	position: absolute;
	top: 5px !important;
	right: 10px !important;
	width: 20px;
}
.select2-search--dropdown .select2-search__field {
	padding: 0px !important;
	width: 100%;
	box-sizing: border-box;
	border-radius: 5px !important;
}
.select2-container--default .select2-results__option--highlighted.select2-results__option--selectable {
	background-color: #2885bb !important;
	border-radius: 5px !important;
	padding: 5px !important;
	font-size: 0.8rem !important;
}

.select2-results__option {
	padding: 5px !important;
	font-size: 0.8rem !important;
	user-select: none;
	-webkit-user-select: none;
}

.select2-dropdown {
	background-color: #e0e2ee !important;
	border: 1px solid #acacac !important;
	padding: 3px !important;
	border-radius: 10px !important;
	box-sizing: border-box;
	display: block;
	position: absolute;
	left: -100000px;
	width: 100%;
	z-index: 1051;
}

/* button-dropdown */
.button-dropdown {
	border-radius: 10px !important;
}

.date-height {
	height: 30px;
}

.form-control-width {
	width: 90%;
}

.wrapper .option {
	background: #fff;
	/* display: flex; */
	justify-content: space-evenly;
	margin: 0 5px;
	border-radius: 5px;
	cursor: pointer;
	transition: all 0.3s ease;
}
.wrapper .option .dot {
	/* height: 20px;
  width: 20px; */
	background: #d9d9d9;
	border-radius: 50%;
	position: relative;
}
.wrapper .option .dot::before {
	position: absolute;
	content: "";
	/* top: 4px;
  left: 4px;
  width: 12px;
  height: 12px; */
	background: #0069d9;
	border-radius: 50%;
	opacity: 0;
	transform: scale(1.5);
	transition: all 0.3s ease;
}
input[type="radio"] {
	display: none;
}

.radio-text-checkout {
	font-style: normal;
	font-weight: normal;
	font-size: 12px !important;
	line-height: 15px;
}

#option-1,
#option-2,
#option-3,
#option-4 {
	height: 26px;
}

#option-1:checked:checked ~ .option-1,
#option-2:checked:checked ~ .option-2,
#option-3:checked:checked ~ .option-3,
#option-4:checked:checked ~ .option-4 {
	border: none !important;
}
#option-1:checked:checked ~ .option-1 .dot,
#option-2:checked:checked ~ .option-2 .dot,
#option-3:checked:checked ~ .option-3 .dot,
#option-4:checked:checked ~ .option-4 .dot {
	background: #fff;
}
#option-1:checked:checked ~ .option-1 .dot::before,
#option-2:checked:checked ~ .option-2 .dot::before,
#option-3:checked:checked ~ .option-3 .dot::before,
#option-4:checked:checked ~ .option-4 .dot::before {
	opacity: 1;
	transform: scale(1);
}

#option-1:checked:checked ~ .option-1 span,
#option-2:checked:checked ~ .option-2 span,
#option-3:checked:checked ~ .option-3 span,
#option-4:checked:checked ~ .option-4 span {
	color: black;
}
/* 
.card {
  margin-bottom: 30px;
  border: 0;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  letter-spacing: 0.5px;
  border-radius: 8px;
  -webkit-box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
  box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
} */

.card .card-header {
	background-color: black;
	border-bottom: none;
	padding: 24px;
	border-bottom: 1px solid #f6f7fb;
	border-top-left-radius: 8px;
	border-top-right-radius: 8px;
}

.card-header:first-child {
	border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}

.card .card-body {
	padding: 30px;
	background-color: transparent;
}

.btn-primary,
.btn-primary.disabled,
.btn-primary:disabled {
	background-color: #4466f2 !important;
	border-color: #4466f2 !important;
}

/* Spinner */
.spin-div {
	position: fixed;
	top: 50%;
	left: 50%;
	/* bring your own prefixes */
	transform: translate(-50%, -50%);
}

.lds-roller {
	display: inline-block;
	position: relative;
	width: 80px;
	height: 80px;
}
.lds-roller div {
	animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
	transform-origin: 40px 40px;
}
.lds-roller div:after {
	content: " ";
	display: block;
	position: absolute;
	width: 7px;
	height: 7px;
	border-radius: 50%;
	background: #ffc901;
	margin: -4px 0 0 -4px;
}
.lds-roller div:nth-child(1) {
	animation-delay: -0.036s;
}
.lds-roller div:nth-child(1):after {
	top: 63px;
	left: 63px;
}
.lds-roller div:nth-child(2) {
	animation-delay: -0.072s;
}
.lds-roller div:nth-child(2):after {
	top: 68px;
	left: 56px;
}
.lds-roller div:nth-child(3) {
	animation-delay: -0.108s;
}
.lds-roller div:nth-child(3):after {
	top: 71px;
	left: 48px;
}
.lds-roller div:nth-child(4) {
	animation-delay: -0.144s;
}
.lds-roller div:nth-child(4):after {
	top: 72px;
	left: 40px;
}
.lds-roller div:nth-child(5) {
	animation-delay: -0.18s;
}
.lds-roller div:nth-child(5):after {
	top: 71px;
	left: 32px;
}
.lds-roller div:nth-child(6) {
	animation-delay: -0.216s;
}
.lds-roller div:nth-child(6):after {
	top: 68px;
	left: 24px;
}
.lds-roller div:nth-child(7) {
	animation-delay: -0.252s;
}
.lds-roller div:nth-child(7):after {
	top: 63px;
	left: 17px;
}
.lds-roller div:nth-child(8) {
	animation-delay: -0.288s;
}
.lds-roller div:nth-child(8):after {
	top: 56px;
	left: 12px;
}
@keyframes lds-roller {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

.text-checkout {
	font-weight: 600;
	font-size: 14px;
	line-height: 24px;
	/* identical to box height, or 150% */

	letter-spacing: 0.01em;

	color: rgba(0, 0, 0, 0.7);
}

.text-edit {
	color: rgba(0, 0, 0, 0.7);
}

.edit-icon {
	color: rgba(0, 0, 0, 0.7);
}

.product-cart {
	font-style: normal;
	font-weight: 500;
	font-size: 11px !important;
	letter-spacing: 0.15px;
	color: rgba(0, 0, 0, 0.7);
	white-space: nowrap;
	width: 70%;
	overflow: hidden;
	text-overflow: ellipsis;
}

.product-total-price {
	font-style: normal;
	font-weight: 700;
	font-size: 16px !important;
	letter-spacing: 0.15px;
	color: rgba(0, 0, 0, 0.94);
}

.hr-line {
	color: rgb(0 0 0 / 30%);
	margin: 5px 0 8px 0;
}
.product-quantity {
	font-style: normal;
	font-weight: 600;
	font-size: 10px !important;
	letter-spacing: 0.15px;
	color: rgba(0, 0, 0, 0.94);
	white-space: nowrap;
	width: 25%;
	overflow: hidden;
	text-overflow: ellipsis;
}

.text-area-bg.input-box {
	background-color: white !important;
}

.checkout-info-text {
	font-style: normal;
	font-size: 14px !important;
	font-weight: 500;
	line-height: 24px;
	letter-spacing: 0.01em;
	color: #000000;
	text-align: left !important;
}

#checkbox-icon {
	color: green;
	margin-right: 5px;
}

.form-control.address-input::placeholder {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	font-style: normal;
	font-weight: normal;
	font-size: 13px;
	line-height: 15px;
}
.inner-text,
.form-control.address-input {
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 15px;
}
.location-text.checkout-location-text {
	width: 100%;
	font-weight: normal;
	font-size: 10px;
	line-height: 12px;
	margin-right: 5px;
}
.current-location-card {
	background-color: white;
	border: 1px solid #006c07;
	border-radius: 5px;
}
#address-field,
.form-control.address-input {
	background-color: white;
	border: none;
}
.address-input-group {
	background-color: white;
	border: 1px solid black;
	border-radius: 5px;
}

.form-control.address-input:focus {
	box-shadow: none;
	outline: 0 none;
}

.ant-picker.date-picker-field {
	background-color: white;
	border: 1px solid black;
	border-radius: 5px;
}

.checkout-scroll {
	max-height: calc(100vh - 56px);
	overflow-y: auto;
	overflow-x: hidden;
}

.currency-mini {
	font-size: 14px;
}

.product-cart {
	font-size: 14px !important;
}

.checkout-product-title {
	font-style: normal;
	font-weight: 600;
	font-size: 14px !important;
	letter-spacing: 0.15px;
	color: rgba(0, 0, 0, 0.7);
	white-space: nowrap;
	max-width: 50%;
	overflow: hidden;
	text-overflow: ellipsis;
}

.checkout-product-quantity {
	font-style: normal;
	font-size: 12px !important;
	letter-spacing: 0.15px;
	color: rgba(0, 0, 0, 0.94);
	white-space: nowrap;
	max-width: 50%;
	overflow: hidden;
	text-overflow: ellipsis;
}

.btn-yellow {
	background-color: #ffc901;
	border-color: #ffc901;
	color: #1d1e2c;
}

.cart-empty-text {
	font-style: normal;
	font-weight: 700;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: 0.01em;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	max-width: 100%;
}

.user-text-overflow {
	max-width: 100%;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.user-text-overflow > div {
	font-weight: 600;
	color: black;
}

.btn-checkout {
	border-radius: 5px;
}

.pl-1 {
	padding-left: 0.375rem !important;
}
.vertical-divider {
	width: 1px;
	border: 1px dashed #bdbdbd;
	align-self: stretch;
}
.offer-container {
	position: relative;
	background-color: white;
}
.offer-container::before {
	content: "";
	position: absolute;
	top: 50%;
	left: -11px;
	width: 22px;
	height: 22px;
	border-radius: 100%;
	background-color: #f3f3f3;
	transform: translateY(-50%);
}
.offer-container::after {
	content: "";
	position: absolute;
	top: 50%;
	right: -11px;
	width: 22px;
	height: 22px;
	border-radius: 100%;
	background-color: #f3f3f3;
	transform: translateY(-50%);
}
.offer-modal .ant-modal-header {
	background-color: #f3f3f3;
	border-color: #bdbdbd;
}
