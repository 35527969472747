/* Navbar  */
.nav-deliver {
	margin-top: 2px;
	font-size: 10px;
	margin-bottom: 0;
}

.nav-deliver-location {
	font-size: 12px;
}

.navbar-toggler {
	font-size: 12px !important;
}

.form-control {
	height: 30px;
}

.searchbox {
	width: 80% !important;
	border: none !important;
	height: 50px;
	border-radius: 15px 0 0 15px;
}
.searchbox-icon {
	border: none !important;
	background-color: #fff !important;
	color: rgba(189, 189, 189, 0.7);
	border-radius: 0 15px 15px 0;
}

.text-collapse {
	text-decoration: none !important;
}

.nav-link {
	color: #00629b !important;
	font-weight: bold;
	border-bottom: 1px solid rgb(228, 228, 228) !important;
	padding: 10px;
	margin: 0;
}
#nav-title {
	font-size: 0.96rem !important;
	font-weight: bold;
	margin-bottom: 0px;
	width: 80%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	text-align: left;
	opacity: 0.6;
}

.navbar-icon {
	color: rgb(73, 73, 73) !important;
	font-weight: 600 !important;
}

.offcanvas-header {
	align-items: center;
	padding: 0.8rem;
	display: flex;
}

.offcanvas-body {
	display: block !important;
}

.select-style {
	border: none;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	-ms-appearance: none;
}
.navbar-brand {
	color: #bdbdbd;
}
.delivery-location {
	display: flex;
	cursor: pointer;
}
#location-icon {
	margin-right: 5px;
	height: 16px;
	/* color: #fd9827; */
}
.hamburger-toggler {
	border: none;
	background: none;
}
.searchbox::placeholder {
	color: #bdbdbd;
}
input[type="text"]:focus,
.searchbox:focus {
	box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px #f297303f;
	outline: 0 none;
}

.nav-link-style {
	border: none !important;
}

#home-icon,
#user-icon,
#my-orders-icon {
	height: 14px;
	margin-left: 0;
}

#user-icon,
#my-orders-icon {
	margin-right: 0.15rem;
}
.sticky-top {
	position: sticky !important;
	top: 0;
	z-index: 2;
}

.location-text {
	font-style: normal;
	font-weight: 600;
	font-size: 12px;
	line-height: 13px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.offcanvas-end {
	padding-left: 1rem;
}

.category-select {
	background-color: #ffc901;
	border-color: #0000004f;
	font-weight: bold;
}

.page-title {
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 37px;
	align-items: center;
	letter-spacing: 0.2px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	max-width: 70%;
}

.category-dropdown {
	max-height: 100px;
	overflow-y: scroll;
	overflow-x: hidden;
}

.ant-btn-primary {
	background: #1d1e2c;
	border: #1d1e2c;
}

.ant-btn-primary:hover,
.ant-btn-primary:focus {
	color: #fff;
	border-color: #006c67;
	background: #006c67;
}

.scrollable-category {
	width: 100%;
	cursor: pointer;
}
#collapseCategory {
	max-height: 80vh;
	overflow: auto;
	width: 100%;
}

.on-scroll-search {
	box-shadow: 8px 2px 7px 4px rgb(0 0 0 / 5%);
}

.on-scroll-search input,
.on-scroll-search span {
	border-radius: 0;
}

.navbar-brand > img {
	object-fit: cover;
	object-position: center;
}

.navbar-root{
	position: relative;
	height: 40px;
	width: 100vw;
}
#icon-left-component,#navbar-icon,#icon-right-component{
	position: absolute;
	top: 0;
	left: 0;
}
#navbar-icon{
	left: 50%;
	transform: translateX(-50%);
}
#icon-right-component{
	left: calc(100% - 2px);
	transform: translate(-50%,50%);
}
#icon-left-component{
	top: 50%;
    transform: translateY(-50%);
    width: calc(50% - 30px);
}