.user-information {
    border-radius: 10px;
  }
  
.text-grey {
    color: rgb(150, 150, 150) !important;
}
  
.user-text h6{
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  max-width: 200px;
  /* identical to box height, or 150% */

  letter-spacing: 0.01em;
  color: #006c67;
}

.user-text-overflow{
  max-width: 150px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}