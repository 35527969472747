.text-middle {
	margin: 0;
	position: absolute;
	top: 50%;
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
}

.btn-yellow {
	background-color: #ffc901;
	border-color: #ffc901;
	color: #1d1e2c;
}

.lead {
	font-style: normal;
	font-weight: 400;
	font-size: 18px;
	line-height: 24px;
	letter-spacing: 0.01em;
}

.lead span {
	font-weight: 600;
}
