.text-gray {
	color: rgb(117, 117, 117);
	font-size: 0.75rem;
}

.text-cart {
	font-weight: 600;
	font-size: 14px;
	line-height: 24px;
	letter-spacing: 0.01em;
	color: rgba(0, 0, 0, 0.7);
}
.dp-thumb {
	width: 60px !important;
	height: 60px !important;
}
.pay-thumb {
	width: 50px !important;
	height: 40px !important;
}
.bkash-thumb {
	width: 70px;
}

.infotext {
	font-size: 12px !important;
}

.infodata {
	color: rgb(0, 0, 143);
}
.smalltext {
	font-size: 8px !important;
}
.confirm-btn {
	bottom: 0 !important;
	margin-top: auto;
}
.lefttxt {
	text-align: left;
}

.mapouter {
	position: relative;
	text-align: right;
	height: 350px;
	width: 275px;
}
.gmap_canvas {
	overflow: hidden;
	background: none !important;
	height: 350px;
	width: 270px;
}

.cart-img {
	width: 75px;
	height: 75px;
	border-radius: 5px 0 0 5px;
}

.img-dp {
	width: 60px !important;
	height: 60px !important;
}

.icon-color {
	color: #46598adc;
}

.delete-color {
	color: #d32222;
	margin-bottom: 9px;
}

.promo-code-input {
	border-radius: 10px !important;
}

.text-grey {
	color: rgb(150, 150, 150) !important;
}

.text-small {
	font-size: 0.8rem;
}
.text-mid {
	font-size: 0.9rem;
}
.text-weight {
	font-weight: 600 !important;
}

.text-grey {
	color: #2c2b2b;
}
.text-link {
	color: #00629b;
}

.bg-grey {
	background-color: #f3f3f3;
}

.btn-border {
	border: 1px solid #d7dbe0 !important;
}
.btn-promo {
	background-color: #ffadad !important;
	font-size: 0.8rem !important;
	height: 70% !important;
	border-radius: 10px !important;
	margin-top: 0.6rem !important;
}

.card-border {
	border-radius: 5px;
}

.cart-content {
	max-width: 166px;
}
.cart-product-title {
	font-size: 14px;
	font-weight: 700;
	line-height: 1.25;
	overflow: hidden;
	max-width: 95%;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.quantity-ranger {
	max-width: 100%;
}
.quantity-ranger .webview-btn.custom-webview-btn{
	font-size: 10px;
    font-weight: bolder;
    line-height: 1;
    padding: 3px;
    border-radius: 3px;
	cursor: pointer;
}
.quantity-ranger .webview-btn.custom-webview-btn:hover{
	opacity: .8;
}

.quantity-ranger .icon-color {
	color: #006c67;
}

.cart-text-quantity {
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 12px;
	letter-spacing: 0.01em;
}

.cart-product-price {
	font-size: 14px;
	font-weight: bold;
}

.cart-currency-logo {
	font-weight: 900;
}

.cart-subtotal-price {
	font-style: normal;
	font-weight: 500;
	font-size: 14px !important;
	letter-spacing: 0.15px;
	color: rgba(0, 0, 0, 0.7);
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.cart-total-price {
	font-style: normal;
	font-weight: 700;
	font-size: 17px !important;
	letter-spacing: 0.15px;
	color: rgba(0, 0, 0, 0.94);
}

.each-product-price {
	max-width: 50%;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.each-product-price span {
	font-style: normal;
	font-weight: 600;
	font-size: 12px;
	letter-spacing: 0.15px;
	color: rgba(0, 0, 0, 0.94);
}

.btn-yellow {
	background-color: #ffc901;
	border-color: #ffc901;
	color: #1d1e2c;
}

.cart-empty-text {
	font-style: normal;
	font-weight: 700;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: 0.01em;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	max-width: 100%;
}

.product-variations {
	font-size: 11px;
	font-weight: 600;
	line-height: 1.25;
	overflow: hidden;
	max-width: 100%;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.cart {
	max-height: calc(100vh - 80px);
}

.bg-pricing{
	background-color: #e7e7e7;
    border-radius: 5px;
    padding: 5px 0;
}
.divider-section .ant-divider{
	position: relative;
}
.divider-section .ant-divider::after{
	content: '';
    position: absolute;
    top: 0;
    right: -10px;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
    border-radius: 100%;
    background-color: #F3F3F3;
}
.divider-section .ant-divider::before{
	content: '';
    position: absolute;
    top: 0;
    left: -10px;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
    border-radius: 100%;
    background-color: #F3F3F3;
}
.cart-subtotal-price.price-digit{
	font-size: 16px !important;
}