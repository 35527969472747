/* #modal-product {
  position: fixed;
  top: 15;
  width: 100%;
  left: 50%; 
 transform: translate(-50%); 
 margin: 0px;
} */

/* #modal-product.fade .modal-dialog {
  transform: translate3d(0, 55vh, 0);
  position: fixed;

} */
/* .modal.in .modal-dialog {
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translate(-50%);
 transform: translate3d(0, 0, 0);
}  */

a {
  text-decoration: none;
}

/* Product */
.product-section {
  padding-bottom: 20px;
}

.product-bar {
  /* margin-left: 0.2vh !important; */
  margin-right: 0.2vh !important;
}

.product-price {
  font-size: 0.94rem !important;
  font-weight: bold;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.product-price.price-modal {
  color: #1d1e2c !important;
}

.resized-img {
  height: 50px;
  width: 50px;
}
.bold {
  font-weight: 700;
}

@media screen and (max-width: 600px) {
  .product-card {
    width: 80%;
    height: fit-content;
    border-radius: 15px !important;
  }
}

.line-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.fix-height {
  height: 2.5rem;
}

.add-btn {
  color: #fff;
  background-color: #006c67;
  border: none;
}

.add-btn:focus {
  color: #fff;
  background-color: #01504c;
  border: none;
}
.add-btn:hover {
  color: #fff;
  background-color: #2c6d6a;
}
.add-btn:checked {
  color: #fff;
  background-color: #2c6d6a;
}

.modal-ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.modal-li {
  float: left;
}

.modal-body-scroll {
  max-height: 95vh;
  overflow-y: scroll;
  overflow-x: hidden;
}

.modal-body-scroll::-webkit-scrollbar {
  width: 0;
}

@media screen and (max-width: 600px) {
  .modal-bottom .modal-dialog {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    max-width: 100% !important;
    margin: 0;
  }
  .modal-bottom .modal-dialog .modal-content {
    border-radius: 0.3rem 0.3rem 0 0;
  }
  .modal-p-bottom .modal-dialog {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    max-width: 100% !important;
    margin: 0;
    transform: translateY(100%) !important;
  }
  .modal-p-bottom .modal-dialog .modal-content {
    border-radius: 0.3rem 0.3rem 0 0;
  }
  .modal-p-bottom.show {
    overflow: hidden;
  }
  .modal-p-bottom.show .modal-dialog {
    transform: translateY(50%) !important;
    min-height: 80px;
  }
}
.modal-body {
  padding: 0px !important;
}

.size-box {
  width: 2rem !important;
  height: 1.5rem !important;
  color: #46598a;
  border: 1px solid #46598a;
  font-size: 0.8rem !important;
  padding-top: 1px !important;
  margin: auto !important;
}

.text-blue {
  color: #3d4469 !important;
  font-weight: 600 !important;
}

.btn-attributes {
  border: 1px solid rgb(180, 180, 180);
  border-radius: 5px !important;
}
.btn-qtn {
  border: none !important;
  border-radius: 5px !important;
}
.btn-footer {
  color: black;
  border-radius: 5px !important;
}
.btn-bg {
  background-color: #ececec;
}
.text-small {
  font-size: 0.8rem !important;
}
.text-header {
  font-size: 1rem !important;
  padding-left: 20px;
}
.Modal {
  overflow: hidden;
}
.modal-50w {
  border-radius: 10px !important;
}
.border-radius-2 div {
  border-radius: 10px;
}

.modal-box {
  width: 100% !important;
  border-radius: 10px !important;

  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}
.modal-height {
  /* height: 100vh !important; */
  border-radius: 10px !important;
}
.modal-dialog {
  /* height: 100vh !important; */
  border-radius: 10px !important;
}
.vl {
  border-left: 1px solid #222222 !important;
}

.modal-footer .div-footer {
  width: 100% !important;
  margin: 0;
  background-color: rgb(255, 255, 255);
  border-top: 1px solid #efefef !important;
  position: fixed !important;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

/* radio */

.radio-button-label-size > input {
  display: none;
}

.radio-button-label-color > input {
  display: none;
}

.radio-button-label-color > input:checked + div {
  border-radius: 7px !important;
  background-color: rgb(255, 255, 255);
  color: #1d4d96;
}
.radio-button-label-color > input:checked + .btn-attributes {
  border: 2px solid #2885bb !important;
  border-radius: 7px !important;
  font-weight: 500 !important;
}

.modal-text-area {
  border-radius: 7px !important;
  background-color: rgb(255, 255, 255);
  color: #1d4d96 !important;
  font-size: 0.8rem !important;
}

.box img {
  width: 100%;
  height: 71px;
  object-fit: cover;
  object-position: center;
  display: block; /* remove extra space below image */
}
.box {
  width: 95px;
}

.product-card {
  border-radius: 10px;
  cursor: pointer;
}
.product-img {
  border-radius: 10px 0 0 10px;
}

.grid-product-img{
  border-radius: 10px;
  height: 159.25px;
  width: 100%;
  object-fit: cover;
  object-position: center;
}
.p-10{
  padding: 10px;
}

.product-title {
  font-size: 0.75rem;
  font-weight: 700;
  line-height: 1.25;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.product-description {
  margin: 0 !important;
  padding-left: 0 !important;
  text-align: left !important;
  max-height: 3.59999rem;
}
.product-description {
  font-size: 0.6rem;
  font-weight: 300;
  line-height: 1.28571;
  margin-top: 4px;
  margin-bottom: 0;
  max-height: 3.59999rem;
  overflow: hidden;
  hyphens: auto;
  line-clamp: 2;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  padding-right: 0;
  margin-left: 0 !important;
}
.discount-price {
  color: #bdbdbd;
  font-size: 12px;
}

#modal-product {
  display: flex;
  align-items: flex-end;
  padding: 0;
  margin: 0;
  position: fixed;
  width: 100%;
}

.title-modal {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.01em;
}
.stock-status {
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
}
.modal-currency {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  max-width: 220px;
  letter-spacing: 0.01em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

#qtn {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */

  letter-spacing: 0.01em;
}

.description-modal {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 13px;

  color: rgba(0, 0, 0, 0.6);
  padding-bottom: 10px;
}

.attribute-title {
  font-style: normal;
  font-weight: 600;
  font-size: 14px !important;
  line-height: 24px;
  /* identical to box height, or 171% */

  letter-spacing: 0.01em;

  color: #000000;
}
#updatedPrice .currency-logo {
  height: 10px;
}

.modal-body {
  padding-bottom: 10px !important;
}
.modal-dialog {
  max-width: 100% !important;
}

#modal-product .modal-content {
  height: 100vh;
  top: 50%;
  transform: translateY(-50%);
}

.product-modal-close-btn {
  background-color: #ffc901;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 100%;
  transform: translate(-100%);
  z-index: 1020;
}

.price-text {
  font-size: 0.75rem;
}

.image-gallery-thumbnail-image {
  height: 55px;
}

.modal-p-bottom.show .modal-dialog {
  transform: translateY(50%) !important;
}

.modalDetails {
  position: absolute;
  z-index: 20000;
  left: 100%;
  transform: translateX(-100%);
  border-radius: 100%;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
}

.modalDetails button {
  margin: 0 auto !important;
}

.modalDetails button:focus {
  box-shadow: 0 0 0 rgba(0, 0, 0, 0) inset, 0 0 0 black;
  outline: 0 none;
}

.image-gallery-thumbnail .image-gallery-thumbnail-image {
  vertical-align: middle;
  width: 100%;
  line-height: 0;
  object-fit: contain;
  object-position: center;
}

#cart-qtn {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
}

.cart-btn-disabled {
  color: gray;
  opacity: 0.6;
}

/* Hide scrollbar for Chrome, Safari and Opera */
::-webkit-scrollbar {
  display: none;
}

.accordion-item {
  border: none;
}

.accordion-content {
  padding-left: 24px;
}

.btn-square{
  width: 25px;
  height: 25px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 11px;
  cursor: pointer;
}
.btn-square:hover{
  opacity: .8;
}