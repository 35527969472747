bg-grey {
	background-color: #f3f3f3 !important;
}
a {
	text-decoration: none !important;
	/* font-family: montserrat; */
}
/* .full-height {
  height: auto;
} */

.navbar-toggler,
.navbar-toggler:active,
.navbar-toggler-icon:focus {
	border: none !important;
}

img[alt] {
	font-size: 0.75em;
}

.cate-wrapper {
	max-height: 250px;
	overflow-x: scroll;
	overflow-y: hidden;
}

.cate-wrapper::-webkit-scrollbar {
	width: 0;
	height: 0;
}

.full-row {
	margin-left: 0px !important;
	margin-left: 0px !important;
}

.img-dp {
	width: 60px !important;
	height: 60px !important;
}

.text-grey {
	color: rgb(150, 150, 150) !important;
}

.text-small {
	font-size: 0.8rem;
}
.order-id {
	font-style: normal;
	font-weight: 600;
	font-size: 13px;
	letter-spacing: 0.15px;
	color: rgba(0, 0, 0, 0.94);
}
.text-mid {
	font-style: normal;
	font-weight: 600;
	font-size: 12px;
	letter-spacing: 0.15px;
}

.text-dy {
	color: #dbac00;
}

.text-weight {
	font-weight: 600 !important;
}

.text-grey {
	color: #242424;
}
.text-link {
	color: #00629b;
}

.bg-grey {
	background-color: #f3f3f3;
}

.select-box {
	background-color: #e0e2ee !important;
	font-size: 0.8rem !important;
	border: 1px solid #e0e2ee00 !important;
	padding: 0.4rem !important;
	color: #727272 !important;
	width: 90% !important;
	border-radius: 10px !important;
}
.select-box :focus {
	border: 1px solid #460505;
}

.input-box ::placeholder {
	/* Chrome, Firefox, Opera, Safari 10.1+ */
	color: red !important;
	opacity: 1; /* Firefox */
}

.btn-outline-secondary {
	border: #013553;
}
.btn-border {
	border: 1px solid #d7dbe0 !important;
}
.btn-promo {
	background-color: #ffadad !important;
	font-size: 0.8rem !important;
	height: 70% !important;
	border-radius: 10px !important;
	margin-top: 0.6rem !important;
}

.full-height {
	height: 85vh;
}

.searchbox {
	width: 80% !important;
	border: none !important;
}
.searchbox-icon {
	border: none !important;
	background-color: #fff !important;
}

/* Datepicker */
#datepicker {
	width: 180px;
	margin: 0 20px 20px 20px;
}
#datepicker > span:hover {
	cursor: pointer;
}

.select2-drop-active {
	margin-top: -25px;
}

.radio-button-label > input {
	display: none;
}
.radio-button-label > img {
	cursor: pointer;
	border: 3px solid #ddd;
	width: 150px;
}

.radio-button-label > input:checked + img {
	border: 3px solid #2885bb;
}

.select2-container .select2-selection--single .select2-selection__rendered {
	font-size: 0.8rem !important;
}

.select2-container--default .select2-selection--single {
	background-color: #e0e2ee !important;
	border: 1px solid #e0e2ee00 !important;
	border-radius: 10px !important;
	margin: 5px !important;
}
.select2-container--default
	.select2-selection--single
	.select2-selection__arrow {
	height: 26px;
	position: absolute;
	top: 5px !important;
	right: 10px !important;
	width: 20px;
}
.select2-search--dropdown .select2-search__field {
	padding: 0px !important;
	width: 100%;
	box-sizing: border-box;
	border-radius: 5px !important;
}
.select2-container--default
	.select2-results__option--highlighted.select2-results__option--selectable {
	background-color: #2885bb !important;
	border-radius: 5px !important;
	padding: 5px !important;
	font-size: 0.8rem !important;
}

.select2-results__option {
	padding: 5px !important;
	font-size: 0.8rem !important;
	user-select: none;
	-webkit-user-select: none;
}

.select2-dropdown {
	background-color: #e0e2ee !important;
	border: 1px solid #acacac !important;
	padding: 3px !important;
	border-radius: 10px !important;
	box-sizing: border-box;
	display: block;
	position: absolute;
	left: -100000px;
	width: 100%;
	z-index: 1051;
}

.warpper {
	flex-direction: column;
	align-items: left;
}
.tab {
	font-weight: 600;
	font-size: 14px;
	line-height: 24px;
	letter-spacing: 0.01em;
	color: rgba(0, 0, 0, 0.7);
	cursor: pointer;
	padding: 10px 20px;
	margin: 0px 2px;
	display: inline-block;
	border-radius: 3px 3px 0px 0px;
}
.tab-active {
	color: #006c67;
	font-weight: 700;
}

.panels {
	min-height: 200px;
	width: 100%;
	max-width: 500px;
	border-radius: 3px;
	overflow: hidden;
	padding: 20px;
}
.panel {
	display: none;
	animation: fadein 0.8s;
}
@keyframes fadein {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}
.panel-title {
	font-size: 1.5em;
	font-weight: bold;
}
.radio {
	display: none;
}
#one:checked ~ .panels #one-panel,
#two:checked ~ .panels #two-panel,
#three:checked ~ .panels #three-panel {
	display: block;
}
#one:checked ~ .tabs #one-tab,
#two:checked ~ .tabs #two-tab,
#three:checked ~ .tabs #three-tab {
	color: #1660cf;
	border-bottom: 4px solid #1660cf;
}

.tabs {
	position: relative;
}

.collapse-card {
	background-color: white;
	padding: 1vh;
	border-radius: 10px;
}

.collapse-card-parent {
	border: 1px solid rgba(0, 0, 0, 0.1);
}

.pending-txt {
	color: #c78f00;
	font-weight: bold;
}
.delivered-txt {
	color: #006800;
	font-weight: bold;
}
.cancelled-txt {
	color: #8a0000;
	font-weight: bold;
}

.btn-reorder {
	background-color: rgb(248, 200, 200);
	padding-left: 10px;
	padding-right: 10px;
	padding-top: 0px;
	padding-bottom: 2px;
	font-weight: bold;
	border-radius: 5px;
	z-index: 2;
}

.collapse-card {
	z-index: 1;
}

.profile-inputs {
	background-color: white !important;
	font-size: 0.8rem !important;
	border: 1px solid #e0e2ee00 !important;
	height: 38px !important;
	font-style: normal;
	font-weight: normal;
	font-size: 12px !important;
	line-height: 15px;
}

.form-control.profile-inputs:focus {
	box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px #f297303f;
	outline: 0 none;
}

.profile-input-title {
	font-weight: 600;
	font-size: 14px;
	line-height: 24px;
	letter-spacing: 0.01em;
	color: rgba(0, 0, 0, 0.7);
}

.product-cart.order-item-subtoal {
	width: 100%;
}

.order-item .product-cart {
	max-width: 50%;
	font-style: normal;
	font-weight: 500;
	font-size: 11px !important;
	letter-spacing: 0.15px;
	color: rgba(0, 0, 0, 0.7);
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.order-item-total {
	font-style: normal;
	font-weight: 700;
	font-size: 11px;
	letter-spacing: 0.15px;
	color: rgba(0, 0, 0, 0.94);
}

.account-scroll {
	max-height: calc(100vh - 56px);
	overflow-y: auto;
	overflow-x: hidden;
}

.ordered-product {
	max-width: 70%;
}

.ordered-product-title {
	font-style: normal;
	font-weight: 500;
	font-size: 11px !important;
	letter-spacing: 0.15px;
	color: rgba(0, 0, 0, 0.7);
	white-space: nowrap;
	max-width: 50%;
	overflow: hidden;
	text-overflow: ellipsis;
}

.ordered-product-quantity {
	font-style: normal;
	font-weight: 600;
	font-size: 10px !important;
	letter-spacing: 0.15px;
	color: rgba(0, 0, 0, 0.94);
	white-space: nowrap;
	max-width: 50%;
	overflow: hidden;
	text-overflow: ellipsis;
}

.order-item-price {
	max-width: 30%;
	font-weight: 600;
	font-size: 10px !important;
	letter-spacing: 0.15px;
	color: rgba(0, 0, 0, 0.94);
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
