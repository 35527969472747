@import "~antd/dist/antd.css";
.App {
  text-align: center;
  overflow-x: hidden;
  position: relative;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body {
  overflow-y: scroll !important;
  padding-right: 0px !important;
}

.slick-next:before,
.slick-prev:before {
  color: #0000002e;
}

.overflow-scroll {
  overflow: scroll !important;
}

.theme-circle-icon {
  padding: 5px;
  border-radius: 100%;
  background-color: #006c67;
  color: white !important;
  font-size: 9px;
}

.theme-check-input.form-check-input:focus {
  border-color: rgba(0, 108, 103, 0.5);
  box-shadow: 0 0 0 0.25rem rgba(0, 108, 103, 0.25);
}
.theme-check-input.form-check-input:checked {
  background-color: #006c67;
  border-color: #006c67;
}

.theme-radios .ant-radio-checked .ant-radio-inner {
  border-color: #006c67 !important ;
}

.theme-radios .ant-radio-checked .ant-radio-inner:after {
  background-color: #006c67;
}

.theme-radios .ant-radio:hover .ant-radio-inner {
  border-color: #006c67;
}
.theme-radios .ant-radio-checked .ant-radio-inner:focus {
  border-color: #006c67;
}
.text-black{
  color: black !important;
}
.opacity-50{
	opacity: .5;
}
.font-weight-normal{
  font-weight: normal;
}

.mapModalTitle{
  width: 100% !important;
}

.mapModalTitle > p{
  width: 100% !important;
}

#search-box::placeholder {
  color: #252626;
}

#search-box {
   color: #252626;
}
a:hover{
  opacity: .6;
  transition: all .3s ease-in-out;
}

.text-price.text-black{
  color: black !important;
}
.shipping-charge.text-black{
  color: black !important;
}
.fw-600{
  font-weight: 600;
}
.border-radius-5{
  border-radius: 5px !important;
}
.ant-modal .ant-modal-content{
  border-radius: 10px;
}
.ant-modal-content .ant-modal-header{
  border-radius: 10px 10px 0 0;
}
.ant-modal-content .ant-modal-footer{
  border-radius: 0 0 10px 10px;
}
.border-radius-10{
  border-radius: 10px !important;
}

@media screen and (min-width: 576px) {
  body{
    background-image: url('../public/assets/images/mobile-frame.png');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 426px 100vh;
  }
  .modal-footer .div-footer {
    border-radius: 0 0 12px 12px;
  }
  .App{
    width: 414px !important;
    height: calc(100vh - 5vh) !important;
    margin: 0 auto !important;
    padding-top: 4.5vh;
  }
  .home-scroll{
    border-radius: 12px;
    height: calc(100vh - 9.5vh - 66px) !important;
    padding-bottom: 0 !important;
  }
  .ant-modal{
    width: 400px !important;
  }
  #modal-product .modal-body-scroll{
    height: calc(100vh - 10vh - 69px);
  }
  #modal-product .modal-content{
    width: 414px;
    height: calc(100vh - 9.5vh) !important;
    margin-top: 3vh;
    left: 50%;
    top: 0;
    transform: translate(-50%,-50%) !important;
    border-radius: 12px;
  }
  #modal-product .image-gallery-image, #modal-product .modal-body-scroll{
    border-radius: 12px;
  }
  #modal-product .modal-box{
    height: auto !important;
  }
  .Toastify__toast-container--top-right {
      top: 5.5vh;
      right: 50%;
      transform: translateX(66%);
      z-index: 10;
  }
  .Toastify__toast-container--top-center {
    top: 4.3vh;
  }
  .offcanvas.offcanvas-end{
    width: 340px !important;
    height: calc(100vh - 9.5vh);
    top: 4.5vh;
    right: 50%;
    transform: translateX(61%);
    border-radius: 0 12px 12px 0;
  }
  .offcanvas.offcanvas-end .navbar-nav {
    flex-direction: column;
  }
  .modal-p-bottom.show #modal-product.modal-dialog {
    height: calc(100vh - -5.4vh) !important;
    top: -60% !important;
    transform: translateY(40%) !important;
  }
}