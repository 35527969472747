.border-radius-footer {
	height: 56px;
	border-radius: 10px;
}

.border-radius-button {
	border-radius: 10px !important;
}

.text-price {
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 16px;
}

.cart-notification-div {
	border-radius: 50%;
	width: 16px;
	height: 16px;
	margin-left: 28px;
	margin-top: 8px;
	position: absolute;
}
.cart-notification-text {
	font-weight: bold;
	font-size: 0.6rem;
	color: black;
}
.footer-text {
	color: black;
	font-weight: 500;
	font-size: 12px;
	line-height: 16px;
}
.shipping-charge {
	font-weight: 500;
	font-size: 10px;
	line-height: 16px;
	opacity: 0.5;
}

#home-icon,
#cart-icon {
	width: 20px;
	height: 17px;
	margin: 0 auto;
}
.currency-logo {
	font-weight: 900;
}

.checkout-btn {
	border: none;
}

.w-4 {
	width: 4rem;
}

w-5 {
	width: 5rem;
}

.copyright {
	font-size: 12px;
}

.mb-6 {
	margin-bottom: 5rem;
}

.bottom-12{
	bottom: 12px;
}
.px-15{
	padding-left: 15px;
	padding-right: 15px;
}
.custom-box-shadow{
	box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.cursor-pointer{
	cursor: pointer;
}
.cursor-pointer:hover{
	opacity: .6;
	transition: all .3s ease-in-out;
}