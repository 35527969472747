.subcategories {
	margin-left: 12px;
	position: relative;
	text-align: left;
	overflow-x: auto;
	width: 100%;
	white-space: nowrap;
}

.cate-wrapper {
	overflow-x: scroll;
	overflow-y: hidden;
}

.cate-wrapper::-webkit-scrollbar {
	width: 0;
	height: 0;
}

.box-sub {
	height: 20px !important;
	max-width: 80px !important;
}

.left-arrow-sub {
	position: absolute;
	left: -11px;
	cursor: pointer;
	z-index: 1;
}

.right-arrow-sub {
	position: absolute;
	right: 1px;
	cursor: pointer;
	z-index: 1;
}
