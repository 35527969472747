.resized {
    height: 110px;
    width: 120px;
  }
  
  .subcategory-title{
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
    width: 90%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .categorical-products-title{
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    opacity: 0.9;
  }