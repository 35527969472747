.radio-button-label-size > input:checked + .btn-attributes{
    border: none !important;
    color: black;
}
.btn-attributes{
    height: 26px;
    display: flex;
    align-items: center;
}
.radio-text{
    font-size: .75rem;
}