#location-modal {
	border-radius: 10px;
}
.location-title {
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 16px;
	padding-left: 10px;
}
.product-categories-text {
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 14px;
	letter-spacing: -0.05em;
}

.search-results-title {
	font-style: normal;
	font-weight: normal;
	font-size: 11px;
	line-height: 13px;
	letter-spacing: -0.05em;
	color: rgb(0 0 0 / 46%);
}

.home-scroll {
	height: 100vh;
	overflow-y: auto;
	overflow-x: hidden;
	padding-bottom: 56px;
}

.footer {
	max-height: 10vh;
}

#subcategories {
	max-width: 100%;
	overflow-x: scroll;
}

.products-found {
	font-style: normal;
	font-weight: 500;
	font-size: 10px;
	line-height: 19px;
	color: #bdbdbd;
}
